import React from "react";
import { Route, Routes } from "react-router-dom";
import { SiderBar, TopBar } from "./components/Narv";
import { Stake } from "./pages/Stake";
import { Swap } from "./pages/Swap";
import Alert from "./components/Alert";
import Loading from "./components/Loading";
import Trade from "./pages/Trade/Trade";
import Info from "./pages/Info/Info";
import Market from "./pages/Market/Market";
import Deposit from "./components/Deposit";
import StakeRewards from "./components/StakeRewards";
import ClaimRewards from "./components/ClaimRewards";
import Withdraw from "./components/Withdraw";

function App () {
  return (
    <div className="relative sm:-8 p-4 bg-[#13131a] min-h-screen flex flex-row text-white">
      <div className="sm:flex hidden mr-6 relative">
        <SiderBar />
    
      </div>

      <div className="flex-1 max-sm:w-full mx-auto">
        <TopBar />
        <Routes>
          <Route path="/" element={<Trade />}/>
          <Route path="/swap" element={<Swap />} />
          <Route path="/stake" element={<Stake />} />
          <Route path="/trade" element={<Trade />} />
          <Route path="/market" element={<Market />} />
          <Route path="/info" element={<Info />} />
        </Routes>
      </div>
      <Deposit />
      <StakeRewards />
      <ClaimRewards />
      <Withdraw />
      <Loading />
      <Alert />
    
    </div>
     

  );
}

export default App;
