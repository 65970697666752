import React from 'react'
import Achart from '../../components/Chart/Achart'
import Mchart from '../../components/Chart/Mchart'

const Market = () => {
  return (
    <div className="flex flex-col w-full" >
        <div>
            <Achart />
        </div>
        <div className=''>
            <Mchart />
        </div>
  </div>    
  )
}

export default Market