import React, { useState } from "react";
import { useAccount } from "wagmi";
import { parseUnits } from "ethers/lib/utils";
import { nft } from "../../assets/img";
import stake from "../../assets/img/stake.png";

import { setGlobalState, setAlert } from "../../store";
import {
  useAlreadyMint,
  useRole,
  useIsPledge,
  useNFTBalance,
  useIsWithdraw,
} from "../../utils";
import {
  getGorillaPledgeTime,
  getGorillaPledgeTimeGold,
  getGorillaPledgeTimeSilver,
  goldMint,
  goldPledge,
  // goldWithdraw,
  mint,
  pledge,
  silverMint,
  silverPledge,
  // silverWithdraw,
  // withdraw,
} from "../../Blockchain.Services";
import { useMount, useInterval } from "ahooks";
import {
  useAlreadyMintGold,
  useAlreadyMintSilver,
  useGoldNFTBalance,
  useIsPledgeGold,
  useIsPledgeSilver,
  useIsWithdrawGold,
  useIsWithdrawSilver,
  useRoleGold,
  useRoleSilver,
  useSliverNFTBalance,
} from "../../utils/helpers";

const NFTStake = () => {
  // 获取当前账户
  const { address } = useAccount();
  // 是否有mint权限
  const hasRole = useRole(address);
  const hasRoleSilver = useRoleSilver(address);
  const hasRoleGold = useRoleGold(address);
  // 是否已经mint过了
  const alreadyMint = useAlreadyMint(address);
  const alreadyMintSilver = useAlreadyMintSilver(address);
  const alreadyMintGold = useAlreadyMintGold(address);

  // NFT的余额
  const NFTBalance = useNFTBalance(address);
  const SilverNFTBalance = useSliverNFTBalance(address);
  const GoldNFTBalance = useGoldNFTBalance(address);
  //NFT的余额是否大于0
  const NFTBalanceIsGreaterThan0 = NFTBalance?.gt(parseUnits("0"));
  const SilverNFTBalanceIsGreaterThan0 = SilverNFTBalance?.gt(parseUnits("0"));
  const GoldNFTBalanceIsGreaterThan0 = GoldNFTBalance?.gt(parseUnits("0"));

  // 是否已经质押过了
  const isPledge = useIsPledge(address);
  const isPledgeSilver = useIsPledgeSilver(address);
  const isPledgeGold = useIsPledgeGold(address);

  //   是否已经提现
  const isWithdraw = useIsWithdraw(address);
  const isWithdrawSilver = useIsWithdrawSilver(address);
  const isWithdrawGold = useIsWithdrawGold(address);

  const [pledgeTime, setPledgeTime] = useState(0);
  const [pledgeTimeSilver, setPledgeTimeSilver] = useState(0);
  const [pledgeTimeGold, setPledgeTimeGold] = useState(0);

  const [countdown, setCountdown] = useState(0);
  const [countdownSilver, setCountdownSilver] = useState(0);
  const [countdownGold, setCountdownGold] = useState(0);

  useMount(() => {
    getGorillaPledgeTime(address).then((res) => {
      setPledgeTime(res);
    });
  });

  useMount(() => {
    getGorillaPledgeTimeSilver(address).then((res) => {
      setPledgeTimeSilver(res);
    });
  });
  useMount(() => {
    getGorillaPledgeTimeGold(address).then((res) => {
      setPledgeTimeGold(res);
    });
  });
  // 如果这个时间+30天大于现在的时间，那么已经可以过了
  useInterval(() => {
    if (
      parseInt(pledgeTime) + 2592000 >=
      parseInt(Date.parse(new Date()) / 1000)
    ) {
      setCountdown(
        parseInt(pledgeTime) + 2592000 - parseInt(Date.parse(new Date()) / 1000)
      );
    }
  }, 1000);

  useInterval(() => {
    if (
      parseInt(pledgeTimeSilver) + 2592000 >=
      parseInt(Date.parse(new Date()) / 1000)
    ) {
      setCountdownSilver(
        parseInt(pledgeTimeSilver) +
          2592000 -
          parseInt(Date.parse(new Date()) / 1000)
      );
    }
  }, 1000);
  useInterval(() => {
    if (
      parseInt(pledgeTimeGold) + 2592000 >=
      parseInt(Date.parse(new Date()) / 1000)
    ) {
      setCountdownGold(
        parseInt(pledgeTimeGold) +
          2592000 -
          parseInt(Date.parse(new Date()) / 1000)
      );
    }
  }, 1000);

  function format(time) {
    let day = Math.floor(time / (60 * 60 * 24));
    let hours = Math.floor((time / (60 * 60)) % 24);
    let minutes = Math.floor((time / 60) % 60);
    let seconds = Math.floor(time % 60);
    return day + " D " + hours + " H " + minutes + " M " + seconds + " S ";
  }

  const handleMint = async () => {
    if (address === undefined) {
      setAlert("Please Connct Wallet", "red");
      return;
    }
    if (hasRole === false) {
      setAlert("You do not have permission to obtain this NFT!", "red");
      return;
    }
    if (alreadyMint) {
      setAlert(
        "You've already minted!You can go pledge to earn Gorilla!",
        "red"
      );
      return;
    }
    setGlobalState("showModal", "scale-0");
    setGlobalState("loading", {
      show: true,
      msg: "Mint...",
    });
    try {
      await mint({ address });
      setAlert("Mint Success", "green");
      window.location.reload();
    } catch (error) {
      setAlert("Mint Cancelled", "red");
    }
  };

  const handleMintSilver = async () => {
    if (address === undefined) {
      setAlert("Please Connct Wallet", "red");
      return;
    }
    if (hasRoleSilver === false) {
      setAlert("You do not have permission to obtain this NFT!", "red");
      return;
    }
    if (alreadyMintSilver) {
      setAlert(
        "You've already minted!You can go pledge to earn Gorilla!",
        "red"
      );
      return;
    }
    setGlobalState("showModal", "scale-0");
    setGlobalState("loading", {
      show: true,
      msg: "Mint...",
    });
    try {
      await silverMint({ address });
      setAlert("Mint Success", "green");
      window.location.reload();
    } catch (error) {
      setAlert("Mint Cancelled", "red");
    }
  };
  const handleMintGold = async () => {
    if (address === undefined) {
      setAlert("Please Connct Wallet", "red");
      return;
    }
    if (hasRoleGold === false) {
      setAlert("You do not have permission to obtain this NFT!", "red");
      return;
    }
    if (alreadyMintGold) {
      setAlert(
        "You've already minted!You can go pledge to earn Gorilla!",
        "red"
      );
      return;
    }
    setGlobalState("showModal", "scale-0");
    setGlobalState("loading", {
      show: true,
      msg: "Mint...",
    });
    try {
      await goldMint({ address });
      setAlert("Mint Success", "green");
      window.location.reload();
    } catch (error) {
      setAlert("Mint Cancelled", "red");
    }
  };

  const handlePledge = async () => {
    if (address === undefined) {
      setAlert("Please Connct Wallet", "red");
      return;
    }
    if (!NFTBalanceIsGreaterThan0) {
      setAlert("Please mint Gorilla NFT first!", "red");
      return;
    }

    setGlobalState("showModal", "scale-0");
    setGlobalState("loading", {
      show: true,
      msg: "Pledge...",
    });
    try {
      await pledge({ address });
      setAlert("Pledge Success", "green");
      window.location.reload();
    } catch (error) {
      setAlert("Pledge Cancelled", "red");
    }
  };
  const handlePledgeSilver = async () => {
    if (address === undefined) {
      setAlert("Please Connct Wallet", "red");
      return;
    }
    if (!SilverNFTBalanceIsGreaterThan0) {
      setAlert("Please mint Gorilla NFT first!", "red");
      return;
    }

    setGlobalState("showModal", "scale-0");
    setGlobalState("loading", {
      show: true,
      msg: "Pledge...",
    });
    try {
      await silverPledge({ address });
      setAlert("Pledge Success", "green");
      window.location.reload();
    } catch (error) {
      setAlert("Pledge Cancelled", "red");
    }
  };
  const handlePledgeGold = async () => {
    if (address === undefined) {
      setAlert("Please Connct Wallet", "red");
      return;
    }
    if (!GoldNFTBalanceIsGreaterThan0) {
      setAlert("Please mint Gorilla NFT first!", "red");
      return;
    }

    setGlobalState("showModal", "scale-0");
    setGlobalState("loading", {
      show: true,
      msg: "Pledge...",
    });
    try {
      await goldPledge({ address });
      setAlert("Pledge Success", "green");
      window.location.reload();
    } catch (error) {
      setAlert("Pledge Cancelled", "red");
    }
  };

  const handleWithdraw = async () => {
    if (address === undefined) {
      setAlert("Please Connct Wallet", "red");
      return;
    } else {
      setAlert("The unlocking time has not yet arrived!", "red");
      return;
    }

    // if (isWithdraw) {
    //   setAlert("You have already withdrawn cash!", "red");
    //   return;
    // }
    // setGlobalState("showModal", "scale-0");
    // setGlobalState("loading", {
    //   show: true,
    //   msg: "Withdraw...",
    // });
    // try {
    //   await withdraw({ address });
    //   setAlert("Withdraw Success", "green");
    //   window.location.reload();
    // } catch (error) {
    //   setAlert("Withdraw Cancelled", "red");
    // }
  };

  const handleWithdrawSilver = async () => {
    if (address === undefined) {
      setAlert("Please Connct Wallet", "red");
      return;
    } else {
      setAlert("The unlocking time has not yet arrived!", "red");
      return;
    }

    // if (isWithdrawSilver) {
    //   setAlert("You have already withdrawn cash!", "red");
    //   return;
    // }
    // setGlobalState("showModal", "scale-0");
    // setGlobalState("loading", {
    //   show: true,
    //   msg: "Withdraw...",
    // });
    // try {
    //   await silverWithdraw({ address });
    //   setAlert("Withdraw Success", "green");
    //   window.location.reload();
    // } catch (error) {
    //   setAlert("Withdraw Cancelled", "red");
    // }
  };
  const handleWithdrawGold = async () => {
    if (address === undefined) {
      setAlert("Please Connct Wallet", "red");
      return;
    } else {
      setAlert("The unlocking time has not yet arrived!", "red");
      return;
    }

    // if (isWithdrawGold) {
    //   setAlert("You have already withdrawn cash!", "red");
    //   return;
    // }
    // setGlobalState("showModal", "scale-0");
    // setGlobalState("loading", {
    //   show: true,
    //   msg: "Withdraw...",
    // });
    // try {
    //   await goldWithdraw({ address });
    //   setAlert("Withdraw Success", "green");
    //   window.location.reload();
    // } catch (error) {
    //   setAlert("Withdraw Cancelled", "red");
    // }
  };

  return (
    <div className="flex flex-wrap mt-2 justify-around">
      <div className="basis-full md:basis-[45%] p-2">
        <div className=" w-full rounded-lg overflow-hidden bg-[#181B25] my-2 p-3">
          <img
            src={nft}
            alt="NFT"
            className="h-70 w-full object-cover shadow-lg shadow-[#183226] rounded-lg mb-3"
          />
          <div className=" font-semibold ">
            Gorilla NFT is free mint for Presale contributors!
          </div>

          <div className="w-full m-auto">
            <div className="flex justify-between items-center  text-gray-400 w-full shadow-lg shadow-[rgb(104,72,39)] rounded-md overflow-hidden my-1  p-2">
              <div className="rounded-md shadow-sm shadow-[rgb(104,72,39)] p-2">
                <p>🦍 Mint Permission 🦍</p>
                <p className="text-white font-bold ">
                  {hasRole ? "Unlocked!" : "Not Yet Unlocked!"}
                </p>
              </div>
              <button
                disabled={alreadyMint}
                onClick={handleMint}
                className="bg-[#4acd8d] hover:shadow-xl text-white  hover:shadow-[#183226]  text-xlhover:bg-[#25ff95] cursor-pointer rounded-lg px-5 py-1 border-[1px]  border-[#353949]"
              >
                {alreadyMint ? "Already Mint" : "Free Mint"}
              </button>
            </div>
          </div>

          <div className="w-full m-auto">
            <div className="flex justify-between items-center  text-gray-400 w-full shadow-lg shadow-[rgb(233,233,216)] rounded-md overflow-hidden my-1  p-2">
              <div className="rounded-md shadow-sm shadow-[rgb(233,233,216)] p-2">
                <p>🦍 Mint Permission 🦍</p>
                <p className="text-white font-bold ">
                  {hasRoleSilver ? "Unlocked!" : "Not Yet Unlocked!"}
                </p>
              </div>
              <button
                disabled={alreadyMintSilver}
                onClick={handleMintSilver}
                className="bg-[#4acd8d] hover:shadow-xl text-white  hover:shadow-[#183226]  text-xlhover:bg-[#25ff95] cursor-pointer rounded-lg px-5 py-1 border-[1px]  border-[#353949]"
              >
                {alreadyMintSilver ? "Already Mint" : "Free Mint"}
              </button>
            </div>
          </div>
          <div className="w-full m-auto">
            <div className="flex justify-between items-center  text-gray-400 w-full shadow-lg shadow-[rgb(252,192,86)] rounded-md overflow-hidden my-1  p-2">
              <div className="rounded-md shadow-sm shadow-[rgb(252,192,86)] p-2">
                <p>🦍 Mint Permission 🦍</p>
                <p className="text-white font-bold ">
                  {hasRoleGold ? "Unlocked!" : "Not Yet Unlocked!"}
                </p>
              </div>
              <button
                disabled={alreadyMintGold}
                onClick={handleMintGold}
                className="bg-[#4acd8d] hover:shadow-xl text-white  hover:shadow-[#183226]  text-xlhover:bg-[#25ff95] cursor-pointer rounded-lg px-5 py-1 border-[1px]  border-[#353949]"
              >
                {alreadyMintGold ? "Already Mint" : "Free Mint"}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="basis-full md:basis-[45%] mt-2 md:mt-0 p-2 ">
        <div className="w-full rounded-lg overflow-hidden bg-[#181B25] my-2 p-3 ">
          <img
            src={stake}
            alt="Stake"
            className="h-70 w-full object-cover shadow-lg shadow-[#183226] rounded-lg mb-3"
          />
          <div className="font-semibold">
            You can choose to stake the obtained NFT to earn farming reward!
          </div>
          <div className="w-full m-auto">
            <div className="flex justify-between items-center  text-gray-400 w-full shadow-lg shadow-[rgb(104,72,39)] rounded-md overflow-hidden my-1  p-2">
              <div className="rounded-md shadow-sm shadow-[rgb(104,72,39)] p-2">
                <p>🦍 Withdraw Countdown 🦍</p>
                <p className="text-white font-bold ">
                  {isPledge ? format(countdown) : "You have not pledged yet!"}
                </p>
              </div>
              {isPledge ? (
                <button
                  
                  onClick={handleWithdraw}
                  className="bg-[#4acd8d] hover:shadow-lg text-white  hover:shadow-[#183226] text-xlhover:bg-[#25ff95] cursor-pointer rounded-lg px-5 py-1 border-[1px]  border-[#353949]"
                >
                  {isWithdraw ? "Already Withdraw" : "Withdraw"}
                </button>
              ) : (
                <button
                  
                  onClick={handlePledge}
                  className="bg-[#4acd8d] hover:shadow-lg text-white  hover:shadow-[#183226] text-xlhover:bg-[#25ff95] cursor-pointer rounded-lg px-5 py-1 border-[1px]  border-[#353949]"
                >
                  Pledge Now
                </button>
              )}
            </div>
          </div>
          <div className="w-full m-auto">
            <div className="flex justify-between items-center  text-gray-400 w-full shadow-lg shadow-[rgb(233,233,216)] rounded-md overflow-hidden my-1  p-2">
              <div className="rounded-md shadow-sm shadow-[rgb(233,233,216)] p-2">
                <p>🦍 Withdraw Countdown 🦍</p>
                <p className="text-white font-bold ">
                  {isPledgeSilver
                    ? format(countdownSilver)
                    : "You have not pledged yet!"}
                </p>
              </div>
              {isPledgeSilver ? (
                <button
                  
                  onClick={handleWithdrawSilver}
                  className="bg-[#4acd8d] hover:shadow-lg text-white  hover:shadow-[#183226] text-xlhover:bg-[#25ff95] cursor-pointer rounded-lg px-5 py-1 border-[1px]  border-[#353949]"
                >
                  {isWithdrawSilver ? "Already Withdraw" : "Withdraw"}
                </button>
              ) : (
                <button
                  
                  onClick={handlePledgeSilver}
                  className="bg-[#4acd8d] hover:shadow-lg text-white  hover:shadow-[#183226] text-xlhover:bg-[#25ff95] cursor-pointer rounded-lg px-5 py-1 border-[1px]  border-[#353949]"
                >
                  Pledge Now
                </button>
              )}
            </div>
          </div>
          <div className="w-full m-auto">
            <div className="flex justify-between items-center  text-gray-400 w-full shadow-lg shadow-[rgb(252,192,86)] rounded-md overflow-hidden my-1  p-2">
              <div className="rounded-md shadow-sm shadow-[rgb(252,192,86)] p-2">
                <p>🦍 Withdraw Countdown 🦍 </p>
                <p className="text-white font-bold ">
                  {isPledgeGold
                    ? format(countdownGold)
                    : "You have not pledged yet!"}
                </p>
              </div>
              {isPledgeGold ? (
                <button
                  
                  onClick={handleWithdrawGold}
                  className="bg-[#4acd8d] hover:shadow-lg text-white  hover:shadow-[#183226] text-xlhover:bg-[#25ff95] cursor-pointer rounded-lg px-5 py-1 border-[1px]  border-[#353949]"
                >
                  {isWithdrawGold ? "Already Withdraw" : "Withdraw"}
                </button>
              ) : (
                <button
                
                  onClick={handlePledgeGold}
                  className="bg-[#4acd8d] hover:shadow-lg text-white  hover:shadow-[#183226] text-xlhover:bg-[#25ff95] cursor-pointer rounded-lg px-5 py-1 border-[1px]  border-[#353949]"
                >
                  Pledge Now
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NFTStake;
