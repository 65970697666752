import React, { useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { ethers } from "ethers";
import { parseUnits } from "ethers/lib/utils";
import {
  getAvailableTokens,
  getCounterpartTokens,
  findPoolByTokens,
  useTokenBalance,
  useTokenAllowance,
  isOperationPending,
  getSuccessMessage,
  getFailureMessage,
} from "../../utils";
import { ROUTER_ADDRESS } from "../../constants";
import AmountIn from "./AmountIn";
import AmountOut from "./AmountOut";
import Balance from "./Balance";
import styles from "../../assets/styles";
import { approve, swap } from "../../Blockchain.Services";
import { setGlobalState } from "../../store";
import { setAlert } from "../../store";
import { logo2 } from "../../assets/img";
import { useConnectModal } from "@rainbow-me/rainbowkit";

const Dex = ({ pools }) => {
  const { address } = useAccount();
  const { openConnectModal } = useConnectModal();
  const [fromValue, setFromValue] = useState("");
  const [fromToken, setFromToken] = useState(pools[0].token0Address); // initialFromToken
  const [toToken, setToToken] = useState("");
  const [resetState, setResetState] = useState(false);
  const fromValueBigNumber = parseUnits(fromValue || "0"); // converse the string to bigNumber
  const availableTokens = getAvailableTokens(pools);
  const counterpartTokens = getCounterpartTokens(pools, fromToken);

  // 找交易对的地址  这个好像是空  因为没有To地址
  const pairAddress =
    findPoolByTokens(pools, fromToken, toToken)?.address ?? "";

  // 查余额
  const fromTokenBalance = useTokenBalance(fromToken, address);

  const toTokenBalance = useTokenBalance(toToken, address);

  // 查授权余额
  const tokenAllowance =
    useTokenAllowance(fromToken, address, ROUTER_ADDRESS) || parseUnits("0");

  // 是否需要授权
  const approvedNeeded = fromValueBigNumber.gt(tokenAllowance);

  // 输入框的值是否比0大
  const formValueIsGreaterThan0 = fromValueBigNumber.gt(parseUnits("0"));

  // 余额是否够
  const hasEnoughBalance = fromValueBigNumber.lte(
    fromTokenBalance ?? parseUnits("0")
  );

  const handleApprove = async () => {
    setGlobalState("showModal", "scale-0");
    setGlobalState("loading", {
      show: true,
      msg: "Approve...",
    });
    try {
      await approve(
        address,
        fromToken,
        ROUTER_ADDRESS,
        ethers.constants.MaxUint256
      );
      setAlert("Approve Success!", "green");
      window.location.reload();
    } catch (error) {
      setAlert("Approve Failed", "red");
    }
  };

  const handleSwap = async () => {
    setGlobalState("showModal", "scale-0");
    setGlobalState("loading", {
      show: true,
      msg: "Swap...",
    });
    try {
      await swap(
        address,
        fromValueBigNumber,
        0,
        [fromToken, toToken],
        address,
        Math.floor(Date.now() / 1000) + 60 * 20
      );
      setAlert("Swap Success!", "green");
      setTimeout(() => {
        setFromValue("");
      }, 3000);
    } catch (error) {
      setAlert("Swap Failed!", "red");
    }
  };

  // 可以授权
  const canApprove = approvedNeeded;
  // 可以交换
  const canSwap =
    !approvedNeeded && formValueIsGreaterThan0 && hasEnoughBalance && toToken;

  // 输入修改
  const onFromValueChange = (value) => {
    const trimmedValue = value.trim();

    try {
      trimmedValue && parseUnits(value);
      setFromValue(value);
    } catch (e) {}
  };

  // 代币修改
  const onFromTokenChange = (value) => {
    setFromToken(value);
  };

  const onToTokenChange = (value) => {
    setToToken(value);
  };

  const [mOrl, setMOrL] = useState("maxreturn");
  return (
    <div className="flex flex-col w-full items-center bg-[#13131a] border-[1px] rounded-lg border-[#353949] shadow-lg shadow-[#183226] ">
      <div className="mt-[10px]">
        <img src={logo2} className="w-[80%] m-auto"></img>
      </div>
      <div className="w-[95%] bg-[#181B25] mt-[40px] border-[1px] rounded-lg border-[#353949] shadow-lg shadow-[#183226]">
        <AmountIn
          value={fromValue}
          onChange={onFromValueChange}
          currencyValue={fromToken}
          onSelect={onFromTokenChange}
          currencies={availableTokens}
        />
        <Balance tokenBalance={fromTokenBalance} />
      </div>
      <div className="w-[95%] bg-[#181B25] mt-[40px] border-[1px] rounded-lg border-[#353949] shadow-lg shadow-[#183226]">
        <AmountOut
          fromToken={fromToken}
          toToken={toToken}
          amountIn={fromValueBigNumber}
          pairContract={pairAddress}
          currencyValue={toToken}
          onSelect={onToTokenChange}
          currencies={counterpartTokens}
        />
        <Balance tokenBalance={toTokenBalance} />
      </div>
      <div className="flex w-[95%] justify-start mt-[20px] text-sm text-[#BABAC0]">
        Max Slippage: 0.5%
      </div>
      <div className="flex  w-[95%] mt-[10px]  items-center border-[1px] rounded-lg border-[#353949] ]">
        <button
          className={` basis-1/2 py-1 border-[1px] rounded-lg border-[#353949]  ${
            mOrl === "maxreturn"
              ? "bg-[#4acd8d]"
              : "hover:font-bold hover:bg-[#191B24] hover:shadow-lg  hover:shadow-[#32694f]"
          }`}
          onClick={() => {
            setMOrL("maxreturn");
          }}
        >
          MIX RETURN
        </button>
        <button
          className={` basis-1/2 py-1 border-[1px] rounded-lg border-[#353949] ${
            mOrl === "lowgas"
              ? "bg-[#4acd8d]"
              : "hover:font-bold hover:bg-[#191B24] hover:shadow-lg  hover:shadow-[#32694f]"
          }`}
          onClick={() => {
            setMOrL("lowgas");
          }}
        >
          LOW GAS
        </button>
      </div>
      {!address ? (
        <botton
          onClick={openConnectModal}
          className="bg-[#1dc071] hover:shadow-xl  hover:shadow-[#428254] border-none outline-none px-6 py-2 font-poppins font-bold text-lg rounded-2xl leading-[24px]  mt-[30px] "
        >
          Connect Wallet
        </botton>
      ) : approvedNeeded && hasEnoughBalance ? (
        <button
          disabled={!canApprove}
          onClick={handleApprove}
          className={`${
            canApprove
              ? " bg-[#1dc071] hover:shadow-xl  hover:shadow-[#428254]"
              : "  bg-red-500 hover:shadow-xl  hover:shadow-red-900"
          } border-none outline-none px-6 py-2 font-poppins font-bold text-lg rounded-2xl leading-[24px]  mt-[30px] `}
        >
          Approve
        </button>
      ) : (
        <button
          disabled={!canSwap}
          onClick={handleSwap}
          className={`${
            canSwap
              ? " bg-[#1dc071] hover:shadow-xl  hover:shadow-[#428254] "
              : "2 bg-red-500 hover:shadow-xl  hover:shadow-red-900"
          } border-none outline-none px-6 py-2 font-poppins font-bold text-lg rounded-2xl leading-[24px] mt-[30px]`}
        >
          {toToken
            ? fromValue
              ? hasEnoughBalance
                ? "Swap"
                : "Insufficient balance"
              : "ENTER AN AMOUNT"
            : "SELECT TOKEN"}
        </button>
      )}
    </div>
  );
};

export default Dex;
