import React, { useState } from "react";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import "./Info.css";

const Info = () => {
  const [gmxActiveIndex, setGMXActiveIndex] = useState(null);
  const onGMXDistributionChartEnter = (_, index) => {
    setGMXActiveIndex(index);
  };
  const onGMXDistributionChartLeave = (_, index) => {
    setGMXActiveIndex(null);
  };
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="stats-label">
          <div
            className="stats-label-color"
            style={{ backgroundColor: payload[0].color }}
          ></div>
          {payload[0].value}% {payload[0].name}
        </div>
      );
    }

    return null;
  };

  const [gmxActiveIndex1, setGMXActiveIndex1] = useState(null);
  const onGMXDistributionChartEnter1 = (_, index) => {
    setGMXActiveIndex1(index);
  };
  const onGMXDistributionChartLeave1 = (_, index) => {
    setGMXActiveIndex1(null);
  };
  const CustomTooltip1 = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="stats-label">
          <div
            className="stats-label-color"
            style={{ backgroundColor: payload[0].color }}
          ></div>
          {payload[0].value}% {payload[0].name}
        </div>
      );
    }

    return null;
  };

  let gmxDistributionData = [
    {
      name: `staked`,
      value: 77,
      color: "#4353fa",
    },
    {
      name: `in liquidity`,
      value: 3,
      color: "#0598fa",
    },
    {
      name: `not staked`,
      value: 20,
      color: "#5c0af5",
    },
  ];

  let xGorillaData = [
    {
      name: `USDC`,
      value: 44.17,
      color: "#24e5fa",
    },
    {
      name: `ETH`,
      value: 27.71,
      color: "#0512fa",
    },
    {
      name: `BTC`,
      value: 16.82,
      color: "#5c0565",
    },
    {
      name: `DAI`,
      value: 6.74,
      color: "#5c0015",
    },
    {
      name: `FRAX`,
      value: 1.46,
      color: "#092af5",
    },
    {
      name: `UNI`,
      value: 0.75,
      color: "#250af5",
    },
    {
      name: `LINK`,
      value: 0.66,
      color: "#5c0af5",
    },
  ];
  return (
    <div className="flex flex-col">
      <div className="flex flex-col basis-full bg-[#13131a]  border-[1px] rounded-lg border-[#353949] p-5">
        <div className="text-3xl font-bold">Stats</div>
        <div className="text-sm text-[#BABAC0]">
          BSC Total Stats start from 02 Sep 2023.
        </div>
        <div className="flex  flex-wrap mt-2 justify-around">
          <div className="basis-full  md:basis-[45%] p-2 flex flex-col hover:shadow-lg hover:shadow-[#183226] rounded-lg  bg-[#181B25] my-2">
            <div className=" border-b-[1px] p-6  border-[#353949]">
              Overview (Test Net Data)
            </div>
            <div className="flex flex-row w-full justify-between px-6 mt-[10px] text-[#BABAC0]">
              <div className="text-sm font-bold">AUM</div>
              <div lassName="text-sm text-white">$1,017,317</div>
            </div>
            <div className="flex flex-row w-full justify-between px-6 mt-[5px] text-[#BABAC0]">
              <div className="text-sm font-bold" >xGorilla Pool</div>
              <div>$217,321</div>
            </div>
            <div className="flex flex-row w-full justify-between px-6 mt-[5px] text-[#BABAC0]">
              <div className="text-sm font-bold"> 24h Volume</div>
              <div>$428,329</div>
            </div>
            <div className="flex flex-row w-full justify-between px-6 mt-[5px] text-[#BABAC0]">
              <div className="text-sm font-bold">Long Positions</div>
              <div>$513,517</div>
            </div>
            <div className="flex flex-row w-full justify-between px-6 mt-[5px] text-[#BABAC0]">
              <div className="text-sm font-bold">Short Positions</div>
              <div>$13,679</div>
            </div>
            <div className="flex flex-row w-full justify-between px-6 mt-[5px] text-[#BABAC0] mb-6">
              <div className="text-sm font-bold">Fees since 15 Mar 2023</div>
              <div>$45,137</div>
            </div>
          </div>
          <div className="basis-full  md:basis-[45%] p-2 flex flex-col hover:shadow-lg hover:shadow-[#183226] rounded-lg  bg-[#181B25] my-2">
            <div className=" border-b-[1px] p-6  border-[#353949]">
              Total Stats (Test Net Data)
            </div>
            <div className="flex flex-row w-full justify-between px-6 mt-[10px] text-[#BABAC0]">
              <div className="text-sm font-bold">Total Fees</div>
              <div>$767,254</div>
            </div>
            <div className="flex flex-row w-full justify-between px-6 mt-[5px] text-[#BABAC0]">
              <div className="text-sm font-bold">Total Volume</div>
              <div>$2,484,972</div>
            </div>
            <div className="flex flex-row w-full justify-between px-6 mt-[5px] text-[#BABAC0]">
              <div className="text-sm font-bold">Floor Price Fund</div>
              <div>$15,532</div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col basis-full bg-[#13131a]  border-[1px] rounded-lg border-[#353949] p-5 mt-[30px]">
        <div className="text-3xl font-bold">Tokens</div>
        <div className="text-sm text-[#BABAC0]">
          Gorilla and xGorilla index tokens.
        </div>
        <div className="flex  flex-wrap mt-2 justify-around">
          <div className="basis-full flex flex-row flex-wrap justify-between md:basis-[45%] p-2  hover:shadow-lg hover:shadow-[#183226] rounded-lg  bg-[#181B25] my-2">
            <div className="flex-auto">
              <div className=" border-b-[1px] p-6  border-[#353949]">Gorilla</div>
              <div className="flex flex-row w-full justify-between px-2 mt-[10px] text-[#BABAC0]">
                <div className="text-sm font-bold">Price</div>
                <div>untrackable</div>
              </div>
              <div className="flex flex-row w-full justify-between px-2 mt-[5px] text-[#BABAC0]">
                <div className="text-sm font-bold">Supply</div>
                <div>100,000,000 Gorilla</div>
              </div>
              <div className="flex flex-row w-full justify-between px-2 mt-[5px] text-[#BABAC0]">
                <div className="text-sm font-bold">Total Staked</div>
                <div>$TBA</div>
              </div>
              <div className="flex flex-row w-full justify-between px-2 mt-[5px] text-[#BABAC0]">
                <div className="text-sm font-bold">Market Cap</div>
                <div>$TBA</div>
              </div>
            </div>
            <div className="flex-auto flex flex-col items-center">
              <div className="mt-[10px] text-sm">Distribution</div>
              <div
                className=" stats-piechart"
                onMouseLeave={onGMXDistributionChartLeave}
              >
                {gmxDistributionData.length > 0 && (
                  <PieChart width={200} height={200}>
                    <Pie
                      data={gmxDistributionData}
                      cx={100}
                      cy={100}
                      innerRadius={73}
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="value"
                      startAngle={90}
                      endAngle={-270}
                      paddingAngle={2}
                      // onMouseEnter={onGMXDistributionChartEnter}
                      onMouseOut={onGMXDistributionChartLeave}
                      onMouseLeave={onGMXDistributionChartLeave}
                    >
                      {gmxDistributionData.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={entry.color}
                          style={{
                            filter:
                              gmxActiveIndex === index
                                ? `drop-shadow(0px 0px 6px)`
                                : "none",
                            cursor: "pointer",
                          }}
                          stroke={entry.color}
                          strokeWidth={gmxActiveIndex === index ? 1 : 1}
                        />
                      ))}
                    </Pie>
                    <text
                      x={"50%"}
                      y={"50%"}
                      fill="white"
                      textAnchor="middle"
                      dominantBaseline="middle"
                    >
                      {/* <Trans>Distribution</Trans> */}
                    </text>
                    <Tooltip content={<CustomTooltip />} />
                  </PieChart>
                )}
              </div>
            </div>
          </div>
          <div className="basis-full flex flex-row flex-wrap justify-between md:basis-[45%] p-2  hover:shadow-lg hover:shadow-[#183226] rounded-lg  bg-[#181B25] my-2">
            <div className="flex-auto">
              <div className=" border-b-[1px] p-6  border-[#353949]">xGorilla</div>
              <div className="flex flex-row w-full justify-between px-2 mt-[10px] text-[#BABAC0]">
                <div className="text-sm font-bold" >Price</div>
                <div>untrackable</div>
              </div>
              <div className="flex flex-row w-full justify-between px-2 mt-[5px] text-[#BABAC0]">
                <div className="text-sm font-bold">Supply</div>
                <div>100,000,000xGorilla
                </div>
              </div>
              <div className="flex flex-row w-full justify-between px-2 mt-[5px] text-[#BABAC0]">
                <div className="text-sm font-bold">Total Staked</div>
                <div>$TBA</div>
              </div>
              <div className="flex flex-row w-full justify-between px-2 mt-[5px] text-[#BABAC0]">
                <div className="text-sm font-bold">Market Cap</div>
                <div>$TBA</div>
              </div>
              <div className="flex flex-row w-full justify-between px-2 mt-[5px] text-[#BABAC0]">
                <div className="text-sm font-bold">Stablecoin Percentage</div>
                <div>$TBA</div>
              </div>
            </div>
            <div className="flex-auto flex flex-col items-center">
              <div className="mt-[10px] text-sm">xGorilla Pool</div>
              <div
                className=" stats-piechart"
                onMouseLeave={onGMXDistributionChartLeave1}
              >
                {xGorillaData.length > 0 && (
                  <PieChart width={200} height={200}>
                    <Pie
                      data={xGorillaData}
                      cx={100}
                      cy={100}
                      innerRadius={73}
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="value"
                      startAngle={90}
                      endAngle={-270}
                      paddingAngle={2}
                      onMouseEnter={onGMXDistributionChartEnter1}
                      onMouseOut={onGMXDistributionChartLeave1}
                      onMouseLeave={onGMXDistributionChartLeave1}
                    >
                      {xGorillaData.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={entry.color}
                          style={{
                            filter:
                              gmxActiveIndex === index
                                ? `drop-shadow(0px 0px 6px)`
                                : "none",
                            cursor: "pointer",
                          }}
                          stroke={entry.color}
                          strokeWidth={gmxActiveIndex1 === index ? 1 : 1}
                        />
                      ))}
                    </Pie>
                    <text
                      x={"50%"}
                      y={"50%"}
                      fill="white"
                      textAnchor="middle"
                      dominantBaseline="middle"
                    >
                      {/* <Trans>Distribution</Trans> */}
                    </text>
                    <Tooltip content={<CustomTooltip1 />} />
                  </PieChart>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;
