import { useMount } from "ahooks";
import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { logo, sun } from "../../assets/img";
import { navlinks } from "../../constants";

const Icon = ({ styles, name, imgUrl, isActive, disabled, handleClick }) => (
  <div className="flex justify-between items-center flex-col mb-2 ">
    <div
      className={`w-[48px] h-[48px] rounded-[10px]  ${
        isActive && isActive === name && "bg-[#2c2f32]"
      } flex justify-center items-center ${
        !disabled && "cursor-pointer"
      } ${styles}`}
      onClick={handleClick}
    >
      {!isActive ? (
        <img src={imgUrl} alt="fund_logo" className="w-1/2 h-1/2 " />
      ) : (
        <img
          src={imgUrl}
          alt="fund_logo"
          className={`w-1/2 h-1/2  ${isActive !== name && "grayscale"}`}
        />
      )}
    </div>
    <p className="text-[#4C5262] text-xs">{name}</p>
  </div>
);

const Sidebar = () => {
  const navigate = useNavigate();
  const path = useLocation();

  const [isActive, setIsActive] = useState(
    path.pathname.substring(1) === "" ? "trade" : path.pathname.substring(1)
  );


  return (
    <div className="flex justify-between items-center flex-col sticky top-5 h-[93vh]">
      <Link to="/">
        <img onClick={()=>{setIsActive("trade")}} src={logo} className="w-[60px] rounded-full hover:shadow-xl  hover:shadow-[#32694f] "></img>
      </Link>

      <div className="flex-1 flex flex-col justify-between items-center bg-[#1c1c24] rounded-[15px] w-[76px] py-4 mt-[26px]">
        <div className="flex flex-col justify-center items-center gap-3">
          {navlinks.map((link) => (
            <Icon
              key={link.name}
              {...link}
              isActive={isActive}
              handleClick={() => {
                if (!link.disabled) {
                  setIsActive(link.name);
                  navigate(link.link);
                }
              }}
            />
          ))}
        </div>
        <div>
          <a href="https://linktr.ee/gorillafinance" target="_blank">
          <img src={sun} alt="sun" />
            <div className="text-[#4C5262] text-xs">doc</div>
          </a>
            
          </div>
        {/* <Icon styles="bg-[#1c1c24] shadow-secondary" imgUrl={sun} /> */}
      </div>
    </div>
  );
};

export default Sidebar;
