import erc20 from "./abis/erc20.json";
import factory from "./abis/factory.json";
import pair from "./abis/pair.json";
import router01 from "./abis/router01.json";
import router02 from "./abis/router02.json";
import gfnft from "./abis/gfnft.json";
import xGorilla from "./abis/xGorilla.json";

export default {
  erc20,
  factory,
  pair,
  router01,
  router02,
  gfnft,
  xGorilla
};
