import React from "react";

const Mchart = () => {
  return (
    <div
    
      className='border-[1px] rounded-lg border-[#353949] p-2 bg-[#1c1c24]'
    >
      <iframe
        id="tradingview_d89db"
        src="https://mchart.pages.dev/"
        style={{
          width: "100%",

          margin: "0 !important",
          padding: "0 !important",
        }}
        className="h-[800px]"
      ></iframe>
    </div>
  );
};

export default Mchart;
