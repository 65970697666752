import React from "react";
import { pools } from "../../constants";
import LsDex from "../../components/LsDex/LsDex";
import Achart from "../../components/Chart/Achart";
import Lschart from "../../components/Chart/Lschart";

const Trade = () => {
  return (
    <div className="flex flex-wrap lg:flex-nowrap justify-around">
    <div className=" flex flex-col lg:basis-[65%] sm:basis-full w-full lg:mr-5 shadow-lg shadow-[#183226] ">
      <Achart />
      <Lschart/>
    </div>
    <div className="lg:basis-[35%] lg:shrink-0 sm:basis-full flex justify-center w-full sm:mt-[20px] lg:mt-0">
      <LsDex pools={pools}/>
    </div>
  </div>      
  );
};

export default Trade;
