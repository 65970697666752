import React from "react";
import GFStake from "./GFStake";

import NFTStake from "./NFTStake";

const Stake = () => {
  return (
    <div className="flex flex-wrap">
      <div className="basis-full  bg-[#13131a] border-[1px] rounded-lg border-[#353949]  p-5">
        <div className="text-3xl font-bold">xGorilla Stake</div>
        <GFStake />
      </div>
      <div className="basis-full mt-10 bg-[#13131a] border-[1px] rounded-lg border-[#353949] p-5">
        <div className="text-3xl font-bold">SHARE AND STAKE</div>
          <NFTStake />
      </div>
    </div>
  );
};



export default Stake;
