import {
  createCampaign,
  dashboard,
  payment,
  profile,
  withdraw,
} from "../assets/img";
export const navlinks = [
  {
    name: "trade",
    imgUrl: dashboard,
    link: "/trade",
  },
  {
    name: "stake",
    imgUrl: createCampaign,
    link: "/stake",
  },
  {
    name: "swap",
    imgUrl: payment,
    link: "/swap",
  },
  {
    name: "market",
    imgUrl: profile,
    link: "/market",
  },
  {
    name: "info",
    imgUrl: withdraw,
    link: "/info",
  },
];




// Goerli
// export const ROUTER_ADDRESS = "0x9b75b82ff0FCF785728FBa918466Fa019C1d3aa9";

// export const GFCopperNFT_ADDRESS = "0xC67473FC0f7F1e8cE56e78B47c333AE14e920Cfa";
// export const GFSilverNFT_ADDRESS = "0xE14baAdeaF3B372456070E3415aF725bE9Da1216";
// export const GFGoldNFT_ADDRESS = "0x0466beF9C9116F9c9D9ed8E8bC4509e826f0Ab55";
// export const XGORILLA_ADDRESS = "0x527a3CdEaa686f56F43EC1D1886EaB91b63F6583"
// export const pools = [
//   {
//       address: "0x5911CFEdA2b259Bc5A31b952BF627Ae5b35dBE7D",
//       token0Address: "0x6c542d06f6a48ECecC7DBb094Fa8729B21f07eA5",
//       token0Name: "ETH",
//       token1Address: "0xE043133cDf9cbe87c46F78f683f1c40D25EEe3E7",
//       token1Name: "BNB"
//   }
// ]

// BSC
export const GFCopperNFT_ADDRESS = "0x2863fBe2C26830D34Deeb63a5ce7D4c7E54C235B";
export const GFSilverNFT_ADDRESS = "0x4C01E0aFdc0D00Ae47395f35D7A05976DB1b0d90";
export const GFGoldNFT_ADDRESS = "0xf502E563b1567245f1Ecc9e1F6C41be14C19EB95";
export const XGORILLA_ADDRESS = "0x6393fbb2B717855a35c63BB909C238FB8f562914"

// BSC
// export const GFCopperNFT_ADDRESS = "0xDb88115D6667a0Ae136FD081F3673D040bDe79f0";
// export const GFSilverNFT_ADDRESS = "0xcd7943095E8bBe0d031B8D8e77C634d36314C449";
// export const GFGoldNFT_ADDRESS = "0xe8ffE4569556a12c23549ADEC435da0130926177";
// export const XGORILLA_ADDRESS = "0x0613904570378Ea037330Bef10A5862e950324C1"
export const ROUTER_ADDRESS = "0x10ED43C718714eb63d5aA57B78B54704E256024E";
export const pools = [
  {
    address: "0xD171B26E4484402de70e3Ea256bE5A2630d7e88D",
    token0Address: "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
    token0Name: "BTC",
    token1Address: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
    token1Name: "ETH"
  },
  {
    address: "0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16",
    token0Address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    token0Name: "BNB",
    token1Address: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    token1Name: "BUSD"
  },
  {
    address: "0x0eD7e52944161450477ee417DE9Cd3a859b14fD0",
    token0Address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    token0Name: "BNB",
    token1Address: "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
    token1Name: "Cake"
  },
  {
    address: "0x16b9a82891338f9bA80E2D6970FddA79D1eb0daE",
    token0Address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    token0Name: "BNB",
    token1Address: "0x55d398326f99059fF775485246999027B3197955",
    token1Name: "USDT"
  },
  {
    address: "0xd99c7F6C65857AC913a8f880A4cb84032AB2FC5b",
    token0Address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    token0Name: "BNB",
    token1Address: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
    token1Name: "USDC"
  },
  {
    address: "0x74E4716E431f45807DCF19f284c7aA99F18a4fbc",
    token0Address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    token0Name: "BNB",
    token1Address: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
    token1Name: "ETH"
  },
  {
    address: "0x61EB789d75A95CAa3fF50ed7E47b96c132fEc082",
    token0Address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    token0Name: "BNB",
    token1Address: "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
    token1Name: "BTC"
  },
  {
    address: "0x804678fa97d91B974ec2af3c843270886528a9E6",
    token0Address: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    token0Name: "BUSD",
    token1Address: "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
    token1Name: "Cake"
  },
  {
    address: "0x7EFaEf62fDdCCa950418312c6C91Aef321375A00",
    token0Address: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    token0Name: "BUSD",
    token1Address: "0x55d398326f99059fF775485246999027B3197955",
    token1Name: "USDT"
  },
  {
    address: "0x2354ef4DF11afacb85a5C7f98B624072ECcddbB1",
    token0Address: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    token0Name: "BUSD",
    token1Address: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
    token1Name: "USDC"
  },
  {
    address: "0x7213a321F1855CF1779f42c0CD85d3D95291D34C",
    token0Address: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    token0Name: "BUSD",
    token1Address: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
    token1Name: "ETH"
  },
  {
    address: "0xF45cd219aEF8618A92BAa7aD848364a158a24F33",
    token0Address: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    token0Name: "BUSD",
    token1Address: "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
    token1Name: "BTC"
  },
  {
    address: "0xA39Af17CE4a8eb807E076805Da1e2B8EA7D0755b",
    token0Address: "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
    token0Name: "Cake",
    token1Address: "0x55d398326f99059fF775485246999027B3197955",
    token1Name: "USDT"
  },
  {
    address: "0xA39Af17CE4a8eb807E076805Da1e2B8EA7D0755b",
    token0Address: "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
    token0Name: "Cake",
    token1Address: "0x55d398326f99059fF775485246999027B3197955",
    token1Name: "USDT"
  },
  {
    address: "0x177d955dCA80443A09E7a7F5946cA16b8E0dcA1C",
    token0Address: "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
    token0Name: "Cake",
    token1Address: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
    token1Name: "USDC"
  },
  {
    address: "0x3f23B4F1a35794306ba4f3176934012dC73312D1",
    token0Address: "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
    token0Name: "Cake",
    token1Address: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
    token1Name: "ETH"
  },
  {
    address: "0x3b2B70Dd9684deEE3f942653a418bE34fc0D525b",
    token0Address: "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
    token0Name: "Cake",
    token1Address: "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
    token1Name: "BTC"
  },
  {
    address: "0xEc6557348085Aa57C72514D67070dC863C0a5A8c",
    token0Address: "0x55d398326f99059fF775485246999027B3197955",
    token0Name: "USDT",
    token1Address: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
    token1Name: "USDC"
  },
  {
    address: "0x531FEbfeb9a61D948c384ACFBe6dCc51057AEa7e",
    token0Address: "0x55d398326f99059fF775485246999027B3197955",
    token0Name: "USDT",
    token1Address: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
    token1Name: "ETH"
  },
  {
    address: "0x3F803EC2b816Ea7F06EC76aA2B6f2532F9892d62",
    token0Address: "0x55d398326f99059fF775485246999027B3197955",
    token0Name: "USDT",
    token1Address: "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
    token1Name: "BTC"
  },
  {
    address: "0xEa26B78255Df2bBC31C1eBf60010D78670185bD0",
    token0Address: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
    token0Name: "USDC",
    token1Address: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
    token1Name: "ETH"
  },

  {
    address: "0x2dF244535624761f6fCc381CaE3e9b903429d9Ff",
    token0Address: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
    token0Name: "USDC",
    token1Address: "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
    token1Name: "BTC"
  },
 
];

// // WBNB
// 0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c
// // BUSD
// 0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56

// // CAKE  PancakeSwap Token
// 0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82

// // USDT
// 0x55d398326f99059fF775485246999027B3197955

// // USDC
// 0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d


// // WETH
// 0x2170Ed0880ac9A755fd29B2688956BD959F933F8
// // WBTC
// 0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c

// 0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3
