import React, { useEffect, useState } from "react";
import { Contract } from "@ethersproject/contracts";
import { abis } from "../../contracts";
import { erc20ABI, useAccount, useContractWrite } from "wagmi";
import { ethers } from "ethers";
import { parseUnits } from "ethers/lib/utils";
import Slider, { SliderTooltip } from "rc-slider";
import "rc-slider/assets/index.css";
import "./LsDex.css";
import {
  getAvailableTokens,
  getCounterpartTokens,
  findPoolByTokens,
  useTokenBalance,
  useTokenAllowance,
  useContractFunction,
  isOperationPending,
  getSuccessMessage,
  getFailureMessage,
} from "../../utils";
import { ROUTER_ADDRESS } from "../../constants";
import AmountIn from "./AmountIn";
import AmountOut from "./AmountOut";
import Balance from "./Balance";
import styles from "../../assets/styles";
// import AmountOut from "./AmountOut";
import { approve, swap } from "../../Blockchain.Services";
import { setGlobalState, useGlobalState } from "../../store";
import { setAlert } from "../../store";
import { logo2 } from "../../assets/img";

import longImg from "../../assets/img/long.svg";
import shortImg from "../../assets/img/short.svg";
import swapImg from "../../assets/img/swap.svg";
import { useConnectModal } from "@rainbow-me/rainbowkit";

const LsDex = ({ pools }) => {
  // 当前账号
  const { address } = useAccount();
  const { openConnectModal } = useConnectModal();
  // 输入框的值
  const [fromValue, setFromValue] = useState("");
  // 默认第一个交易对的token0address
  const [fromToken, setFromToken] = useState(pools[0].token0Address); // initialFromToken
  //交换地址
  const [toToken, setToToken] = useState("");
  // 状态更新
  const [resetState, setResetState] = useState(false);
  // 把输入框的值换成bigNumber
  const fromValueBigNumber = parseUnits(fromValue || "0"); // converse the string to bigNumber
  // 获取可用的代币地址
  const availableTokens = getAvailableTokens(pools);

  // 找到to地址
  const counterpartTokens = getCounterpartTokens(pools, fromToken);
  // 找交易对的地址  这个好像是空
  const pairAddress =
    findPoolByTokens(pools, fromToken, toToken)?.address ?? "";

  // 查余额
  const fromTokenBalance = useTokenBalance(fromToken, address);
  const toTokenBalance = useTokenBalance(toToken, address);

  // 查授权余额
  const tokenAllowance =
    useTokenAllowance(fromToken, address, ROUTER_ADDRESS) || parseUnits("0");

  // 是否需要授权
  const approvedNeeded = fromValueBigNumber.gt(tokenAllowance);

  // 输入框的值是否比0大
  const formValueIsGreaterThan0 = fromValueBigNumber.gt(parseUnits("0"));

  // 余额是否够
  const hasEnoughBalance = fromValueBigNumber.lte(
    fromTokenBalance ?? parseUnits("0")
  );

  const [swapApproveState, setSwapApproveState] = useState("nostart");

  const handleApprove = async () => {
    setGlobalState("showModal", "scale-0");
    setGlobalState("loading", {
      show: true,
      msg: "Initializing NFT transfer...",
    });
    try {
      await approve(
        address,
        fromToken,
        ROUTER_ADDRESS,
        ethers.constants.MaxUint256
      );
      setAlert("Transfer completed...", "green");
      window.location.reload();
    } catch (error) {
      console.log("Error transfering NFT: ", error);
      setAlert("Purchase failed...", "red");
    }
  };

  const [swapExecuteState, setSwapExecuteState] = useState("nostart");
  const handleSwap = async () => {
    setGlobalState("showModal", "scale-0");
    setGlobalState("loading", {
      show: true,
      msg: "Initializing NFT transfer...",
    });
    try {
      await swap(
        address,
        fromValueBigNumber,
        0,
        [fromToken, toToken],
        address,
        Math.floor(Date.now() / 1000) + 60 * 20
      );
      setAlert("Transfer completed...", "green");
      setTimeout(() => {
        setFromValue("");
      }, 3000);
    } catch (error) {
      console.log("Error transfering NFT: ", error);
      setAlert("Purchase failed...", "red");
    }
  };

  // // 是否在授权中
  const isApproving = isOperationPending(swapApproveState);
  // // 是否在交换中
  const isSwapping = isOperationPending(swapExecuteState);

  // 可以授权
  const canApprove = !isApproving && approvedNeeded;
  // 可以交换
  const canSwap =
    !approvedNeeded &&
    !isSwapping &&
    formValueIsGreaterThan0 &&
    hasEnoughBalance &&
    toToken;

  // 获取交易成功信息
  const successMessage = getSuccessMessage(swapApproveState, swapExecuteState);
  const failureMessage = getFailureMessage(swapApproveState, swapExecuteState);

  // 输入修改
  const onFromValueChange = (value) => {
    const trimmedValue = value.trim();

    try {
      trimmedValue && parseUnits(value);
      setFromValue(value);
    } catch (e) {}
  };

  // 代币修改
  const onFromTokenChange = (value) => {
    setFromToken(value);
  };

  const onToTokenChange = (value) => {
    setToToken(value);
  };

  // 根据成功和失败的消息重置兑换
  useEffect(() => {
    if (failureMessage || successMessage) {
      setTimeout(() => {
        setResetState(true);
        setFromValue("0");
        setToToken("");
      }, 0);
    }
  }, [failureMessage, successMessage]);
  const [lOrs, setLOrS] = useState("long");
  const [mOrlOrt, setMOrLOrT] = useState("market");
  const leverageMarks = {
    2: "2x",
    5: "5x",
    10: "10x",
    15: "15x",
    20: "20x",
    25: "25x",
    30: "30x",
    35: "35x",
    40: "40x",
    45: "45x",
    50: "50x",
  };

  const [leverage] = useGlobalState("leverage");

  return (
    <div className="flex flex-col w-full items-center bg-[#13131a] border-[1px] rounded-lg border-[#353949] shadow-lg shadow-[#183226] ">
      <div className="mt-[3px]">
        <img src={logo2} className="w-[80%] m-auto"></img>
      </div>
      <div className="flex  w-[95%] mt-[10px]  items-center border-[1px] rounded-lg border-[#353949]">
        <button
          className={` basis-1/2 py-1 border-[1px] rounded-lg border-[#353949]  ${
            lOrs === "long"
              ? "bg-[#4acd8d]"
              : "hover:font-bold hover:bg-[#191B24] hover:shadow-lg  hover:shadow-[#32694f]"
          }`}
          onClick={() => {
            setLOrS("long");
          }}
        >
          LONG
        </button>
        <button
          className={` basis-1/2 py-1 border-[1px] rounded-lg border-[#353949] ${
            lOrs === "short"
              ? "bg-[#4acd8d]"
              : "hover:font-bold hover:bg-[#191B24] hover:shadow-lg  hover:shadow-[#32694f]"
          }`}
          onClick={() => {
            setLOrS("short");
          }}
        >
          SHORT
        </button>
      </div>
      <div className="flex  w-[95%] mt-[10px] cursor-pointer">
        <button
          className={` basis-1/6 py-1  ${
            mOrlOrt === "market" ? "text-[#4acd8d]" : "hover:font-bold"
          }`}
          onClick={() => {
            setMOrLOrT("market");
          }}
        >
          Market
        </button>
        <button
          className={` basis-1/6 py-1   ${
            mOrlOrt === "limit" ? "text-[#4acd8d]" : "hover:font-bold"
          }`}
          onClick={() => {
            setMOrLOrT("limit");
          }}
        >
          Limit
        </button>
        <button
          className={` basis-1/6 py-1 ${
            mOrlOrt === "trigger" ? "text-[#4acd8d]" : "hover:font-bold"
          }`}
          onClick={() => {
            setMOrLOrT("trigger");
          }}
        >
          Trigger
        </button>
      </div>

      <div className="w-[95%] bg-[#181B25] mt-[10px] border-[1px] rounded-lg border-[#353949] shadow-lg shadow-[#183226]">
        <AmountIn
          value={fromValue}
          onChange={onFromValueChange}
          currencyValue={fromToken}
          onSelect={onFromTokenChange}
          currencies={availableTokens}
          isSwapping={isSwapping && hasEnoughBalance}
        />
        <Balance tokenBalance={fromTokenBalance} />
      </div>
      <div className="w-[95%] bg-[#181B25] mt-[10px] border-[1px] rounded-lg border-[#353949] shadow-lg shadow-[#183226]">
        <AmountOut
          fromToken={fromToken}
          toToken={toToken}
          amountIn={fromValueBigNumber}
          pairContract={pairAddress}
          currencyValue={toToken}
          onSelect={onToTokenChange}
          currencies={counterpartTokens}
        />
      </div>

      <div className="w-[95%] flex-col mt-[10px]">
        <div className="mb-2 text-md text-[#BABAC0]">Leverage slider</div>
        <div>
          <Slider
            min={1.1}
            max={50}
            step={0.1}
            marks={leverageMarks}
            // handle={leverageSliderHandle}
            onChange={(value) => setGlobalState("leverage", value)}
            // defaultValue={leverageOption}
          />
        </div>
      </div>
      <div className="flex flex-col  w-[95%] mt-[30px] items-center mb-[15px]">
        <div className="flex flex-row w-full justify-between items-center  ">
          <div className="text-sm text-[#BABAC0]">Collateral In</div>
          <div>USD</div>
        </div>
        <div className="flex flex-row w-full justify-between items-center ">
          <div className="text-sm text-[#BABAC0]">Leverage</div>

          <div>{leverage}x</div>
        </div>

        <div className="flex flex-row w-full justify-between items-center  ">
          <div className="text-sm text-[#BABAC0]">Entry Price</div>

          <div className="flex">
            <div className="lds-dual-ring scale-[0.2] w-8 h-2"> </div>
            <div className="text-sm text-[#BABAC0]">
              {" "}
              {canSwap ? "caculating" : "waiting"}
            </div>
          </div>
        </div>

        <div className="flex flex-row w-full justify-between items-center  ">
          <div className="text-sm text-[#BABAC0]">Liq. Price</div>

          <div className="flex">
            <div className="lds-dual-ring scale-[0.2] w-8 h-2"> </div>
            <div className="text-sm text-[#BABAC0]">
              {" "}
              {canSwap ? "caculating" : "waiting"}
            </div>
          </div>
        </div>
        <div className="flex flex-row w-full justify-between items-center  ">
          <div className="text-sm text-[#BABAC0]">Fees</div>

          <div className="flex">
            <div className="lds-dual-ring scale-[0.2] w-8 h-2"> </div>
            <div className="text-sm text-[#BABAC0]">
              {" "}
              {canSwap ? "caculating" : "waiting"}
            </div>
          </div>
        </div>
      </div>

      {!address ? (
        <botton
        onClick ={openConnectModal}
          className="bg-site-pink bg-[#1dc071] hover:shadow-xl  hover:shadow-[#428254] border-none outline-none px-6 py-1 font-poppins font-bold text-lg rounded-lg leading-[24px] my-[10px]   "
              
          
        >
          Connect Wallet
        </botton>
      ) : approvedNeeded && hasEnoughBalance ? (
        <button
          disabled={!canApprove}
          onClick={() => {
            alert("Coming soon");
          }}
          className={`${
            canApprove
              ? "bg-site-pink bg-[#1dc071] hover:shadow-xl  hover:shadow-[#428254]  "
              : "bg-site-dim2 text-site-dim2  bg-red-500 hover:shadow-xl  hover:shadow-red-900"
          } border-none outline-none px-6 py-1 font-poppins font-bold text-lg rounded-lg leading-[24px] my-[10px] `}
        >
          approve
        </button>
      ) : (
        <button
          disabled={!canSwap}
          onClick={() => {
            alert("Coming soon");
          }}
          className={`${
            canSwap
              ? "bg-site-pink  bg-[#1dc071] hover:shadow-xl  hover:shadow-[#428254]"
              : "bg-site-dim2 text-site-dim2 bg-red-500 hover:shadow-xl  hover:shadow-red-900"
          } border-none outline-none px-6 py-1 font-poppins font-bold text-lg rounded-lg leading-[24px] my-[10px] `}
        >
          {address
            ? toToken
              ? fromValue
                ? hasEnoughBalance
                  ? "Swap"
                  : "Insufficient balance"
                : "ENTER AN AMOUNT"
              : "SELECT TOKEN"
            : "Connect Wallet"}
        </button>
      )}
    </div>
  );
};

export default LsDex;
