import {
  useGlobalState,
  setGlobalState,
  setAlert,
} from "../store";
import { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { logo2 } from "../assets/img";
import { deposit } from "../Blockchain.Services";
import { useAccount } from "wagmi";
import { parseUnits } from "ethers/lib/utils.js";

const Deposit = () => {
  const { address } = useAccount();
  const [modal] = useGlobalState("modal");
  const [price, setPrice] = useState("");
  const fromValueBigNumber = parseUnits(price || "0");
  
  const handleDeposit = async () => {
    if(price<10){
      setAlert("Min Amount is 10!", "red");
      return
    }
    
    setGlobalState("showModal", "scale-0");
    setGlobalState("loading", {
      show: true,
      msg: "Deposit...",
    });
    try {
      await deposit(
        address,
        fromValueBigNumber
      );
      setAlert("Deposit Success!", "green");
      setPrice("")
      window.location.reload();
    } catch (error) {
      console.log("Error transfering NFT: ", error);
      setAlert("Deposit Cancelled", "red");
    }
  };

  return (
    <div
      className={`fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-black bg-opacity-50 ${modal}`}
    >
      <div className="bg-[#151c25] shadow-lg shadow-[#86de79] rounded-xl w-11/12 md:w-2/5 h-7/12 p-6 ">
        <div className="flex flex-row justify-between items-center">
          <p className=" text-3xl font-bold">Deposit</p>
          <button
            type="button"
            onClick={() => setGlobalState("modal", "scale-0")}
            className="border-0 bg-transparent focus:outline-none"
          >
            <FaTimes className="text-gray-400" />
          </button>
        </div>

        <img src={logo2} alt="logo2" />

        <div className=" bg-gray-800 rounded-xl mt-5">
          <input
            type="number"
            className="block w-full text-lg pl-6 h-[50px] 
                bg-transparent border-0 
                 focus:outline-none focus:ring-0"
            placeholder="Enter xGorilla Amount (Min 10)"
            onChange={(e) => setPrice(e.target.value)}
            value={price}
          />
        </div>
    
          <button
            onClick={handleDeposit}
            className="px-6 py-2 bg-[#6dedb1] mt-[30px] text-lg cursor-pointer rounded-lg  hover:shadow-lg  hover:shadow-[#6dedb1] "
          >
            Deposit Now
          </button>

      </div>
    </div>
  );
};

export default Deposit;
