import {
  useGlobalState,
  setGlobalState,
  setLoadingMsg,
  setAlert,
} from "../store";
import { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { logo2 } from "../assets/img";
import { claimRewards, deposit } from "../Blockchain.Services";
import { useAccount } from "wagmi";
import { parseUnits } from "ethers/lib/utils.js";

const ClaimRewards = () => {
  const { address } = useAccount();
  const [claimrewardsmodal] = useGlobalState("claimrewardsmodal");
  const [price, setPrice] = useState("");
  const fromValueBigNumber = parseUnits(price || "0");

  const handleDeposit = async () => {
  
    setGlobalState("claimrewardsmodal", "scale-0");
    setGlobalState("loading", {
      show: true,
      msg: "ClaimRewards...",
    });
    try {
      await claimRewards(
        address
      );
      setAlert("ClaimRewards Success!", "green");
      setPrice("")
      window.location.reload();
    } catch (error) {
      console.log("Error transfering NFT: ", error);
      setAlert("ClaimRewards Cancelled!", "red");
    }
  };

  return (
    <div
      className={`fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-black bg-opacity-50 ${claimrewardsmodal}`}
    >
      <div className="bg-[#151c25] shadow-lg shadow-[#86de79] rounded-xl w-11/12 md:w-2/5 h-7/12 p-6 ">
        <div className="flex flex-row justify-between items-center">
          <p className=" text-3xl font-bold">ClaimRewards</p>
          <button
            type="button"
            onClick={() => setGlobalState("claimrewardsmodal", "scale-0")}
            className="border-0 bg-transparent focus:outline-none"
          >
            <FaTimes className="text-gray-400" />
          </button>
        </div>

        <img src={logo2} alt="logo2" />

        <div className=" mt-5 text-xl font-bold">
          You can get your rewards!
        </div>
    
          <button
            onClick={handleDeposit}
            className="px-6 py-2 bg-[#6dedb1] mt-[30px] text-lg cursor-pointer rounded-lg  hover:shadow-lg  hover:shadow-[#6dedb1] "
          >
            Claim Now
          </button>

      </div>
    </div>
  );
};

export default ClaimRewards;
