import { setGlobalState, setAlert } from "../../store";
import { useAccount } from "wagmi";
import { useState } from "react";
import { formatUnits } from "ethers/lib/utils.js";
import { useInterval, useMount } from "ahooks";
import {
  getXGorillaBalance,
  getXGorillaDepositAmount,
  getXGorillaFirstTime,
  getXGorillaIsUnlock,
  getXGorillaRewards,
  getXGorillaUnclaimedRewards,
} from "../../Blockchain.Services";

const GFStake = () => {
  const { address } = useAccount();
  const [depositAmount, setDepositAmount] = useState(0);
  const [rewardAmount, setRewardAmount] = useState(0);
  const [unClaimAmount, setUnclaimAmount] = useState(0);
  const [xGorillaBalance, setxGorillaBalance] = useState(0);

  const [firstTime, setFirstTime] = useState(0);
  const [isTimeUnlock, setIsTimeUnlock] = useState(false);
  const [countdown, setCountdown] = useState(0);

  useInterval(() => {
    getXGorillaFirstTime(address).then((res) => {
      setFirstTime(res);
    });
  }, 1000);

  useInterval(() => {
    if (
      parseInt(firstTime) + 2592000 >=
      parseInt(Date.parse(new Date()) / 1000)
    ) {
      setCountdown(
        parseInt(firstTime) + 2592000 - parseInt(Date.parse(new Date()) / 1000)
      );
    }
  }, 1000);

  useMount(() => {
    getXGorillaIsUnlock(address).then((res) => {
      setIsTimeUnlock(res);
    });
  });

  useInterval(() => {
    getXGorillaDepositAmount(address).then((res) => {
      setDepositAmount(formatUnits(res));
    });
  }, 1000);

  useInterval(() => {
    getXGorillaUnclaimedRewards(address).then((res) => {
      setUnclaimAmount(formatUnits(res));
    });
  }, 1000);

  useInterval(() => {
    getXGorillaRewards(address).then((res) => {
      setRewardAmount(formatUnits(res));
    });
  }, 1000);

  useInterval(() => {
    getXGorillaBalance(address).then((res) => {
      setxGorillaBalance(formatUnits(res));
    });
  }, 1000);

  function format(time) {
    let day = Math.floor(time / (60 * 60 * 24));
    let hours = Math.floor((time / (60 * 60)) % 24);
    let minutes = Math.floor((time / 60) % 60);
    let seconds = Math.floor(time % 60);
    return day + " D " + hours + " H " + minutes + " M " + seconds + " S ";
  }

  const handleDeposit = () => {
    if (address === undefined) {
      setAlert("Please Connect Wallet!", "red");
      return;
    }
    if (xGorillaBalance <= 0) {
      setAlert("Insufficient xGorilla Balance!", "red");
      return;
    }
    setGlobalState("modal", "scale-100");
  };
  const handleStakeRewards = () => {
    if (address === undefined) {
      setAlert("Please Connect Wallet!", "red");
      return;
    }
    if (rewardAmount <= 0) {
      setAlert("No Rewards!", "red");
      return;
    }
    setGlobalState("stakerewardmodal", "scale-100");
  };

  const handleClaimRewards = () => {
    if (address === undefined) {
      setAlert("Please Connect Wallet!", "red");
      return;
    }
    if (depositAmount <= 0) {
      setAlert("You haven't made a stack yet!", "red");
      return;
    }
    if (!isTimeUnlock) {
      setAlert("Not Yet Unlocked ", "red");
      return;
    }
    setGlobalState("claimrewardsmodal", "scale-100");
  };

  const handleWithdraw = () => {
    if (address === undefined) {
      setAlert("Please Connect Wallet!", "red");
      return;
    }
    if (depositAmount <= 0) {
      setAlert("You haven't made a stack yet!", "red");
      return;
    }
    if (!isTimeUnlock) {
      setAlert("Not Yet Unlocked ", "red");
      return;
    }
    setGlobalState("withdrawmodal", "scale-100");
  };

  return (
    <div className="flex flex-col md:flex-row w-full  justify-around items-center mx-auto  pt-6">
      <div className="md:w-[45%] w-full">
        <div>
          <h1 className=" text-5xl font-bold">
            Stake xGorilla to Earn <br /> Gorilla
            <br />
            <span className="text-gradient">High</span> Returns
          </h1>
          <p className="text-gray-500 font-semibold text-sm mt-3">
            DEFI finance with low risk and high return.
          </p>
        </div>

        <div className="w-[80%] flex justify-between items-center mt-5">
          <div>
            <p className=" font-bold">xGorilla 2,337,525</p>
            <small className="text-gray-300"> Deposited</small>
          </div>
          <div>
            <p className=" font-bold">Gorilla 584381.25</p>
            <small className="text-gray-300"> Reward</small>
          </div>
          <div>
            <p className=" font-bold">25%</p>
            <small className="text-gray-300">APR</small>
          </div>
          <div>
            <p className=" font-bold">20K+</p>
            <small className="text-gray-300">User</small>
          </div>
        </div>
      </div>

      <div className="shadow-xl shadow-black md:w-[50%] w-full mt-10 md:mt-0 rounded-md overflow-hidden bg-gray-800  hover:shadow-xl  hover:shadow-[#183226] ">
        <div className="flex flex-col basis-full md:basis-[48%] border-[1px] rounded-lg border-[#353949]  bg-[#181B25]">
          <div className="flex justify-between">
            <div className="text-xl font-bold  p-3">Stake Info</div>
            <div className="text-sm font-bold  p-4">
              xGorilla balance: {xGorillaBalance}
            </div>
          </div>

          <div className="w-[80%] m-auto">
            <div className="flex justify-between items-center border border-[#4acd8d] text-gray-400 w-full shadow-xl shadow-black rounded-md overflow-hidden bg-gray-800 my-4 p-3">
              <div className="rounded-md shadow-sm shadow-[#4acd8d] p-2">
                🦍 🦍 🦍
                <h4 className="text-sm">DEPOSITED</h4>
              </div>

              <p className="text-sm font-medium">{depositAmount} xGorilla</p>
            </div>
          </div>
          <div className="w-[80%] m-auto">
            <div className="flex justify-between items-center border border-[#4acd8d] text-gray-400 w-full shadow-xl shadow-black rounded-md overflow-hidden bg-gray-800 my-2 p-3">
              <div className="rounded-md shadow-sm shadow-[#4acd8d] p-2">
                🦍 🦍 🦍
                <h4 className="text-sm">REWARD</h4>
              </div>

              <p className="text-sm font-medium">{rewardAmount} Gorilla</p>
            </div>
          </div>
          <div className="w-[80%] m-auto">
            <div className="flex justify-between items-center border border-[#4acd8d] text-gray-400 w-full shadow-xl shadow-black rounded-md overflow-hidden bg-gray-800 my-2 p-3">
              <div className="rounded-md shadow-sm shadow-[#4acd8d] p-2">
                🦍 🦍 🦍
                <h4 className="text-sm">UnclaimReward</h4>
              </div>

              <p className="text-sm font-medium">{unClaimAmount} Gorilla</p>
            </div>
          </div>
          <div className="flex justify-center mt-[10px]">
            {firstTime == 0
              ? "🦍 Get xGorilla to pledge!"
              : `🦍 Unlock Time: ${format(countdown)}`}
          </div>

          <div className="w-full flex justify-around  my-5 flex-wrap ">
            <button
              onClick={handleDeposit}
              className="bg-[#4acd8d] mt-[10px]  text-xlhover:bg-[#25ff95] cursor-pointer rounded-lg px-5 py-1  hover:shadow-lg  hover:shadow-[#6dedb1]"
            >
              Deposit
            </button>
            <button
              onClick={handleStakeRewards}
              className="bg-[#4acd8d] mt-[10px]  text-xlhover:bg-[#25ff95] cursor-pointer rounded-lg px-5 py-1  hover:shadow-lg  hover:shadow-[#6dedb1]"
            >
              StakeRewards
            </button>

            <button
              onClick={handleClaimRewards}
              className="bg-[#4acd8d] mt-[10px]  text-xlhover:bg-[#25ff95] cursor-pointer rounded-lg px-5 py-1  hover:shadow-lg  hover:shadow-[#6dedb1]"
            >
              ClaimRewards
            </button>
            <button
              onClick={handleWithdraw}
              className="bg-[#4acd8d] mt-[10px]  text-xlhover:bg-[#25ff95] cursor-pointer rounded-lg px-5 py-1  hover:shadow-lg  hover:shadow-[#6dedb1]"
            >
              Withdraw
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GFStake;
