import Web3 from "web3";
import { setAlert } from "./store";
import abis from "./contracts/abis";
import {
  GFCopperNFT_ADDRESS,
  GFGoldNFT_ADDRESS,
  GFSilverNFT_ADDRESS,
  ROUTER_ADDRESS,
  XGORILLA_ADDRESS,
} from "./constants";
const { ethereum } = window;
window.web3 = new Web3(ethereum);
window.web3 = new Web3(window.web3.currentProvider);

const getGFCopperNFTContract = async () => {
  const web3 = window.web3;
  const contract = new web3.eth.Contract(abis.gfnft, GFCopperNFT_ADDRESS);
  return contract;
};

const getGFSilverNFTContract = async () => {
  const web3 = window.web3;
  const contract = new web3.eth.Contract(abis.gfnft, GFSilverNFT_ADDRESS);
  return contract;
};
const getGFGoldNFTContract = async () => {
  const web3 = window.web3;
  const contract = new web3.eth.Contract(abis.gfnft, GFGoldNFT_ADDRESS);
  return contract;
};

const getRouter2Contract = async () => {
  const web3 = window.web3;
  const contract = new web3.eth.Contract(abis.router02, ROUTER_ADDRESS);
  return contract;
};
const getERC20Contract = async (ERC20Address) => {
  const web3 = window.web3;
  const contract = new web3.eth.Contract(abis.erc20.abi, ERC20Address);
  return contract;
};

const getXGorillaContract = async () => {
  const web3 = window.web3;
  const contract = new web3.eth.Contract(abis.xGorilla, XGORILLA_ADDRESS);
  return contract;
};


const mint = async ({ address }) => {
  try {
    const contract = await getGFCopperNFTContract();
    await contract.methods.mint().send({ from: address });
  } catch (error) {
    reportError(error);
  }
};
const pledge = async ({ address }) => {
  try {
    const contract = await getGFCopperNFTContract();
    await contract.methods.pledge().send({ from: address });
  } catch (error) {
    reportError(error);
  }
};
const withdraw = async ({ address }) => {
  try {
    const contract = await getGFCopperNFTContract();
    await contract.methods.withdraw().send({ from: address });
  } catch (error) {
    reportError(error);
  }
};


const silverMint = async ({ address }) => {
  try {
    const contract = await getGFSilverNFTContract();
    await contract.methods.mint().send({ from: address });
  } catch (error) {
    reportError(error);
  }
};
const silverPledge = async ({ address }) => {
  try {
    const contract = await getGFSilverNFTContract();
    await contract.methods.pledge().send({ from: address });
  } catch (error) {
    reportError(error);
  }
};
const silverWithdraw = async ({ address }) => {
  try {
    const contract = await getGFSilverNFTContract();
    await contract.methods.withdraw().send({ from: address });
  } catch (error) {
    reportError(error);
  }
};

const goldMint = async ({ address }) => {
  try {
    const contract = await getGFGoldNFTContract();
    await contract.methods.mint().send({ from: address });
  } catch (error) {
    reportError(error);
  }
};
const goldPledge = async ({ address }) => {
  try {
    const contract = await getGFGoldNFTContract();
    await contract.methods.pledge().send({ from: address });
  } catch (error) {
    reportError(error);
  }
};
const goldWithdraw = async ({ address }) => {
  try {
    const contract = await getGFGoldNFTContract();
    await contract.methods.withdraw().send({ from: address });
  } catch (error) {
    reportError(error);
  }
};


const swap = async (address, value, temp, router, pairAddress, deadline) => {
  try {
    const contract = await getRouter2Contract();

    await contract.methods
      .swapExactTokensForTokens(value, temp, router, pairAddress, deadline)
      .send({ from: address });
  } catch (error) {
    reportError(error);
  }
};
const approve = async (address, cAddress, spender, amount) => {
  try {
    const contract = await getERC20Contract(cAddress);
    await contract.methods.approve(spender, amount).send({ from: address });
  } catch (error) {
    reportError(error);
  }
};

const deposit = async (address, amount) => {
  try {
    const contract = await getXGorillaContract();
    await contract.methods.deposit(amount).send({ from: address });
  } catch (error) {
    reportError(error);
  }
};

const stakeRewards = async (address) => {
  try {
    const contract = await getXGorillaContract();
    await contract.methods.stakeRewards().send({ from: address });
  } catch (error) {
    reportError(error);
  }
};

const claimRewards = async (address) => {
  try {
    const contract = await getXGorillaContract();
    await contract.methods.claimRewards().send({ from: address });
  } catch (error) {
    reportError(error);
  }
};

const withdrawAll = async (address) => {
  try {
    const contract = await getXGorillaContract();
    await contract.methods.withdrawAll().send({ from: address });
  } catch (error) {
    reportError(error);
  }
};

const getXGorillaBalance = async (address) => {
  try {
    if (address === undefined) {
      return 0;
    }
    const contract = await getXGorillaContract();
    return await contract.methods.balanceOf(address).call();
  } catch (error) {
    return 0;
  }
};

const getXGorillaIsUnlock = async (address) => {
  try {
    if (address === undefined) {
      return false;
    }
    const contract = await getXGorillaContract();
    return await contract.methods.isTimeUnlock(address).call();
  } catch (error) {
    return false;
  }
};

const getXGorillaFirstTime = async (address) => {
  try {
    if (address === undefined) {
      return 0;
    }
    const contract = await getXGorillaContract();
    return await contract.methods.getFirstTime(address).call();
  } catch (error) {
    return 0;
  }
};

const getXGorillaDepositAmount = async (address) => {
  try {
 
    if (address === undefined) {
      
      return 0;
    }
    const contract = await getXGorillaContract();
    return await contract.methods.getDepositAmount(address).call();

  } catch (error) {
    return 0;
  }
};

const getXGorillaUnclaimedRewards = async (address) => {
  try {
    if (address === undefined) {
      return 0;
    }
    const contract = await getXGorillaContract();
    return await contract.methods.getUnclaimedRewards(address).call();
  } catch (error) {
    return 0;
  }
};

const getXGorillaRewards = async (address) => {
  try {
    if (address === undefined) {
      return 0;
    }
    const contract = await getXGorillaContract();
    return await contract.methods.getRewards(address).call();
  } catch (error) {
    return 0;
  }
};

const getGorillaPledgeTime = async (address) => {
  try {
    if (address === undefined) {
      return 0;
    }
    const contract = await getGFCopperNFTContract();
    return await contract.methods.pledgeTime(address).call();
  } catch (error) {
    return 0;
  }
};

const getGorillaPledgeTimeSilver = async (address) => {
  try {
    if (address === undefined) {
      return 0;
    }
    const contract = await getGFSilverNFTContract();
    return await contract.methods.pledgeTime(address).call();
  } catch (error) {
    return 0;
  }
};

const getGorillaPledgeTimeGold = async (address) => {
  try {
    if (address === undefined) {
      return 0;
    }
    const contract = await getGFGoldNFTContract();
    return await contract.methods.pledgeTime(address).call();
  } catch (error) {
    return 0;
  }
};


const reportError = (error) => {
  setAlert(JSON.stringify(error), "red");
  throw new Error("No ethereum object.");
};

export {
  mint,
  pledge,
  withdraw,
  swap,
  approve,
  deposit,
  stakeRewards,
  claimRewards,
  withdrawAll,
  getXGorillaBalance,
  getXGorillaIsUnlock,
  getXGorillaFirstTime,
  getXGorillaDepositAmount,
  getXGorillaUnclaimedRewards,
  getXGorillaRewards,
  getGorillaPledgeTime,
  silverMint,
  goldMint,
  getGorillaPledgeTimeSilver,getGorillaPledgeTimeGold,
  silverPledge,goldPledge,
  silverWithdraw,goldWithdraw
};
