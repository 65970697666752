import React from "react";

const Achart = () => {
  return (
    <div
    
      className='border-[1px] rounded-lg border-[#353949] p-2 bg-[#1c1c24] pointer-events-none mb-3'
    >
      <iframe
        id="tradingview_d89db"
        src="https://achart.pages.dev/"
        style={{
          width: "100%",
          margin: "0 !important",
          padding: "0 !important",
        }}
        className=" sm:h-[80px] md:h-[70px] lg:h-[60px]"
      ></iframe>
    </div>
  );
};

export default Achart;
